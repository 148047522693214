import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledBox = styled(Box)`
  && {
    position: relative;
    transform: scale(1);
    background-color: #ffffff;
    border-radius: 0px;

    max-width: 960px;
    margin: 0 auto;
    padding: 18px;
  }
`;

export const LogoImage = styled.img`
  && {
    height: 30px;
    width: 120px;

    position: absolute;
    top: 0;
    left: 15px;
    margin: 35px 0;

    @media (max-width: 850px) {
      position: relative;
      display: flex;
      margin: 10px auto;
      left: 0;
    }
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: LintelMedium;
    font-size: 18px;

    text-align: center;
    color: #162a6b;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    font-family: LintelMedium;
    font-weight: 800;
    font-size: 28px;

    margin-top: -10px;
    text-align: center;
    color: #162a6b;
  }
`;
