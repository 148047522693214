import { Route, Routes } from "react-router-dom";
import Home from "./bundles/home/components/Home";
import { observer } from "mobx-react-lite";

const Router = observer(() => {
  return (
    <Routes>
      <Route path="/*" element={<Home />} />
    </Routes>
  );
});

export default Router;
