import { postRequest } from "../HttpRequest";

export const correctorLink = (link: string) => {
  const url = `${process.env.REACT_APP_CORRETOR_LINK}`;
  return postRequest(url, {
    body: {
      link,
    },
  });
};

export const producerLink = (link: string, corretorEv: string) => {
  const url = `${process.env.REACT_APP_PRODUCER_LINK}`;
  return postRequest(url, {
    body: {
      link,
      corretorEv,
    },
  });
};
