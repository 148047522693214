import { Fragment } from "react";
import { Footer } from "./bundles/common/footer/components/Footer";
import Header from "./bundles/common/header/components/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./routes";

const App = () => {
  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Router />
      <Footer />
    </Fragment>
  );
};

export default App;
