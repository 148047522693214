import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { loadLocalImage } from "../../../utils";

export const StyledBox = styled(Box)`
  && {
    width: 100%;
    float: left;
  }
`;

export const LeftImage = styled.div`
  height: 486px;
  width: 100vw;

  display: table-cell;
  vertical-align: middle;
  text-align: center;

  background-image: url(${loadLocalImage("individual.png")});
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: cover;

  transition: all 0.3s;
`;

export const RightImage = styled.div`
  height: 486px;
  width: 100vw;

  display: table-cell;
  vertical-align: middle;
  text-align: center;

  background-image: url(${loadLocalImage("empresarial.png")});
  background-position-x: left;
  background-repeat: no-repeat;
  background-size: cover;

  transition: all 0.3s;
`;

export const Title = styled(Typography)`
  && {
    font-family: LintelBold;
    font-style: bold;
    font-weight: 700;
    font-size: 32px;

    margin-bottom: 20px;
    color: #ffffff;
  }
`;

export const Subtitle = styled(Typography)`
  && {
    font-family: Lintel;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;

    margin: 20px 70px;
    color: #ffffff;
  }
`;

export const Wrapper = styled(Box)`
  && {
    width: 480px;

    text-align: center;

    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10;
  }
`;

export const WrapperLeft = styled(Wrapper)`
  && {
    right: 0;

    @media (max-width: 900px) {
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
    }
  }
`;

export const WrapperRight = styled(Wrapper)`
  && {
    left: 0;

    @media (max-width: 900px) {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const StyledGrid = styled(Grid)`
  && {
    position: relative;
    overflow: hidden;

    &:hover div:first-child {
      transform: scale(1.1);
    }
  }
`;

export const CorrectorBox = styled(Box)`
  && {
    margin: -48px auto 0px;
    max-width: 724px;
    min-height: 98px;

    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);

    position: relative;
    z-index: 10;
  }
`;

export const CorrectorGrid = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }
`;

export const CorrectorTitle = styled(Typography)`
  && {
    font-family: Lintel;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;

    text-align: center;
    color: #7e7e7e;
  }
`;

export const CorrectorSubtitle = styled(CorrectorTitle)`
  && {
    text-transform: uppercase;
    color: #ed6d04;
  }
`;

export const CorrectorWrapper = styled(Box)`
  && {
    display: inline-block;
    min-height: 98px;
    padding: 15px;
  }
`;
