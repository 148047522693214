import { Grid } from "@mui/material";
import { loadLocalImage } from "../../../../utils";
import {
  AnsImageLink,
  ContainerGrid,
  IconImage,
  Rights,
  StyledBox,
} from "../styles/FooterStyles";

export const Footer = () => {
  return (
    <StyledBox>
      <ContainerGrid>
        <Grid
          container
          marginBottom={2}
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
          sx={{ paddingTop: 2 }}
        >
          <Grid item xs={12} sm={3} md={4} sx={{ padding: "15px 0 15px 15px" }}>
            <a
              href="https://www.facebook.com/SulAmerica"
              target="_blank"
              rel="noreferrer"
            >
              <IconImage src={loadLocalImage("icon-facebook.png", "footer")} />
            </a>
            <a
              href="https://twitter.com/SulAmerica"
              target="_blank"
              rel="noreferrer"
            >
              <IconImage src={loadLocalImage("icon-twitter.png", "footer")} />
            </a>
            <a
              href="https://www.youtube.com/SulAmerica"
              target="_blank"
              rel="noreferrer"
            >
              <IconImage src={loadLocalImage("icon-youtube.png", "footer")} />
            </a>
            <a
              href="https://www.linkedin.com/company-beta/264362/?pathWildcard=264362"
              target="_blank"
              rel="noreferrer"
            >
              <IconImage src={loadLocalImage("icon-linkedin.png", "footer")} />
            </a>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ padding: "15px 0 15px 15px" }}>
            <Rights>©2017 SulAmérica - Todos os direitos reservados</Rights>
          </Grid>
          <Grid item xs={12} sm={3} md={4} sx={{ padding: "15px 15px 15px 0" }}>
            <AnsImageLink href="https://www.gov.br/ans/pt-br" target="_blank">
              <Grid
                container
                alignItems="center"
                flexDirection="row"
                justifyContent={{ sm: "flex-end" }}
              >
                <IconImage src={loadLocalImage("logo-ans.png", "footer")} />
                <IconImage src={loadLocalImage("selo-ans-3.jpg", "footer")} />
                <IconImage src={loadLocalImage("selo-ans.png", "footer")} />
              </Grid>
            </AnsImageLink>
          </Grid>
        </Grid>
      </ContainerGrid>
    </StyledBox>
  );
};
