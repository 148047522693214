import styled from "styled-components";

import { Button as MuiButton } from "@mui/material";

type ButtonType = "button" | "submit" | "reset";

type VariantType = "text" | "contained" | "outlined";

type BackgroundType = "fill" | "empty";

export type ButtonProps = {
  children: React.ReactChild;
  type: ButtonType;
  variant: VariantType;
  background: BackgroundType;
  fontcolor?: string;
  bgcolor?: string;
  width?: string;
  disabled?: boolean;
  underlined?: boolean;
  disableRipple?: boolean;
  $slim?: boolean;
  $squared?: boolean;
  $shadowbottom?: boolean;
  $islink?: boolean;
  $image?: boolean;
  startIcon?: any;
  onClick?: (event: any) => void;
};

export const BaseButton = styled(MuiButton)<ButtonProps>`
  && {
    font-family: LintelMedium;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;

    text-align: center;

    text-transform: none;
    box-shadow: none;
    font-size: ${(props) => (props.$squared ? "14px" : "30px")};
    color: ${(props) =>
      props.fontcolor
        ? props.fontcolor
        : props.variant === "contained"
        ? "white"
        : "#FD6520"};
  }

  ${(props) =>
    !props.$islink
      ? `&& {
    padding: ${
      !props.$image ? (!props.$slim ? "10px 20px" : "5px 20px") : "5px"
    };
    border-radius: ${!props.underlined ? "30px" : "0"};
    border-radius: ${props.$squared ? "4px" : "30px"};
    ${
      props.disabled
        ? `border: none;`
        : `border: ${props.variant !== "text" ? "2px solid" : ""};`
    };
    border-color: ${props.bgcolor ? props.bgcolor : "#FD6520"};
    border-bottom: ${props.underlined ? "2px solid #FD6520" : ""};
    width: ${props.width ? props.width : "100%"};
    background-color: ${
      props.background === "fill"
        ? `${props.bgcolor ? props.bgcolor : "#FD6520"}`
        : "none"
    };

    ${
      props.$shadowbottom
        ? `
      border-radius: 0;
      border-bottom: 1px solid #E4E4E4;
      box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.2);
    `
        : ""
    }
  }

  &:hover {
    && {
      border: ${props.variant !== "text" ? "2px solid" : ""};
      border-color: ${props.bgcolor ? props.bgcolor : "#FF5333"};
      color: ${
        props.background === "fill"
          ? props.fontcolor
            ? props.fontcolor
            : "white"
          : "#FD6520"
      };
      background-color: ${
        props.background === "fill"
          ? props.bgcolor
            ? props.bgcolor
            : "#FD6520"
          : "rgba(241, 144, 33, 0.1)"
      };
      opacity: 0.9;
    }
  }`
      : `&& {
        padding: 0;
        line-height: 1;
        font-weight: 600;
        border-radius: 0;
        text-decoration: underline;
      }
      
      &:hover {
        && {
          text-decoration: underline;
        }
      }`}

  &&.Mui-disabled {
    color: rgba(255, 255, 255, 0.60);
    box-shadow: none;
    background-color: rgba(50, 50, 50, 0.60);
  }
`;
