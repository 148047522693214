import { makeAutoObservable, toJS } from "mobx";

interface IDataCorrector {
  id: string;
  corretor_nome: string;
  corretor_telefone: string;
  ea: string;
  ev: string;
  aa: string;
  corretorLink?: string;
}
interface IDataProducer {
  id: string;
  produtor_nome: string;
  produtor_email: string;
  produtor_telefone: string;
  ea: string;
  ev: string;
  aa: string;
  produtorLink?: string;
}

interface IData {
  corrector: IDataCorrector;
  productor: IDataProducer;
}

class CorrectorAndProducerStore {
  data: IData = {} as IData;
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get loadingStatus() {
    return this.loading;
  }

  get correctorData() {
    return toJS(this.data.corrector);
  }

  get correctorId() {
    return this.data.corrector ? this.data.corrector.id : "";
  }

  get productorData() {
    return toJS(this.data.productor);
  }

  get productorId() {
    return this.data.productor ? this.data.productor.id : "";
  }

  get corOrProdKeys() {
    const keys = toJS(this.data);
    return keys.productor
      ? {
          ev: keys.productor.ev,
          ea: keys.productor.ea,
          aa: keys.productor.aa,
          nomeprod: keys.productor.produtor_email,
        }
      : {
          ev: keys.corrector.ev,
          ea: keys.corrector.ea,
          aa: keys.corrector.aa,
          nomeprod: keys.corrector.corretor_nome,
        };
  }

  setCorrector = (corrector: IDataCorrector) => {
    this.data.corrector = corrector;
  };

  setProductor = (productor: IDataProducer) => {
    this.data.productor = productor;
  };

  setLoading = (status: boolean) => {
    this.loading = status;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CorrectorAndProducerStore();
