/* eslint-disable react-hooks/exhaustive-deps */
import { useState, Fragment, useEffect, useCallback } from "react";
import Logo from "../../../../assets/images/logo.png";
import { LogoImage, StyledBox, SubTitle, Title } from "../styles/HeaderStyles";
import { observer } from "mobx-react-lite";
import useStores from "../../../../hooks/useStores";
import { addZeroLeft, getQueryUrlParam } from "../../../../utils";
import { toast } from "react-toastify";
import {
  correctorLink,
  producerLink,
} from "../../../../gateway/requests/CorrectorProducerRequests";
import { useLocation } from "react-router-dom";

const Header = observer(() => {
  const location = useLocation();
  const { correctorAndProducerStore } = useStores();
  const { setCorrector, setProductor, setLoading } = correctorAndProducerStore;

  const checkURL = () => {
    const urlSplit = location.pathname
      .split("/")
      .filter((entry: any) => entry !== "");

    if (urlSplit.length) {
      window.history.replaceState(
        null,
        "",
        `/?corretora=${urlSplit[0]}${
          urlSplit.length > 1 ? `&corretor=${urlSplit[1]}` : ""
        }`
      );
    }
  };

  const requestCorretorAndProdutorId = useCallback(async () => {
    const { corretorLink, produtorLink } = {
      corretorLink: getQueryUrlParam("corretora"),
      produtorLink: getQueryUrlParam("corretor"),
    };

    setLoading(true);

    let response = await correctorLink(
      corretorLink ? corretorLink : "sulamerica"
    );

    if (response) {
      if (response.status === 200 || response.status === 201) {
        const correctorData = { ...response.data };

        setCorrector(correctorData);
      } else {
        setCorrector({
          id: "4",
          corretor_nome: "SULAMERICA",
          corretor_telefone: "(11) 3035-2222",
          ea: "73582",
          ev: "999999",
          aa: "35520",
          corretorLink: "sulamerica",
        });

        toast.error(
          "Não foi possível recuperar os dados do corretor, tente mais tarde!"
        );
      }
    }

    if (produtorLink) {
      let response = await producerLink(
        produtorLink ? produtorLink : "sulamerica",
        correctorAndProducerStore.correctorData.ev
      );

      if (response) {
        if (response.status === 200 || response.status === 201) {
          const correctorData = { ...response.data[0] };

          setProductor(correctorData);
        } else {
          toast.error(
            "Não foi possível recuperar os dados do produtor, tente mais tarde!"
          );
        }
      }
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    checkURL();
  }, []);

  useEffect(() => {
    requestCorretorAndProdutorId();
  }, [requestCorretorAndProdutorId]);

  return (
    <Fragment>
      <StyledBox id="inicio">
        <LogoImage src={Logo} />
        <Title>
          SulAmérica Odonto, planos com as melhores ofertas e benefícios.
        </Title>
        <SubTitle>Escolha o seu!</SubTitle>
      </StyledBox>
    </Fragment>
  );
});

export default Header;
