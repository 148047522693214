/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from "react";

import { observer } from "mobx-react-lite";
import { Grid } from "@mui/material";
import {
  CorrectorBox,
  CorrectorSubtitle,
  CorrectorTitle,
  CorrectorWrapper,
  LeftImage,
  RightImage,
  StyledGrid,
  Subtitle,
  Title,
  WrapperLeft,
  WrapperRight,
} from "../styles/HomeStyles";
import Button from "../../common/button/components/Button";
import useStores from "../../../hooks/useStores";
import { getQueryUrlParam } from "../../../utils";

const Home = observer(() => {
  const { correctorAndProducerStore } = useStores();

  const redirectURL = (type: string) => {
    const { corretorLink, produtorLink } = {
      corretorLink: getQueryUrlParam("corretora"),
      produtorLink: getQueryUrlParam("corretor"),
    };

    const paramsPF = corretorLink
      ? produtorLink
        ? `?corretora=${corretorLink}&corretor=${produtorLink}`
        : `?corretora=${corretorLink}`
      : produtorLink
      ? `?corretor=${produtorLink}`
      : "";

    const paramsPME = corretorLink
      ? correctorAndProducerStore.correctorData !== undefined &&
        correctorAndProducerStore.correctorData.corretor_nome !==
          "SULAMERICA" &&
        correctorAndProducerStore.correctorData.ev &&
        correctorAndProducerStore.correctorData.ea &&
        correctorAndProducerStore.correctorData.aa
        ? produtorLink
          ? `?ev=${correctorAndProducerStore.correctorData.ev}&ea=${correctorAndProducerStore.correctorData.ea}&aa=${correctorAndProducerStore.correctorData.aa}&np=${correctorAndProducerStore.productorData.produtor_nome}&ep=${correctorAndProducerStore.productorData.produtor_email}`
          : `?ev=${correctorAndProducerStore.correctorData.ev}&ea=${correctorAndProducerStore.correctorData.ea}&aa=${correctorAndProducerStore.correctorData.aa}`
        : ""
      : "";

    if (type === "pf") {
      window.open(process.env.REACT_APP_PF_URL + paramsPF, "_blank");
    } else if (type === "pme") {
      window.open(process.env.REACT_APP_PME_URL + paramsPME, "_blank");
    }
  };

  return (
    <Fragment>
      <Grid
        container
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <StyledGrid item sm={12} md={6}>
          <LeftImage />
          <WrapperLeft>
            <Title>Odonto Individual</Title>
            <Subtitle>
              Planos para você cuidar da sua saúde bucal com os melhores
              profissionais e clínicas do Brasil
            </Subtitle>
            <Button
              type="button"
              variant="contained"
              background="fill"
              bgcolor="#FF6710"
              width="400px"
              onClick={() => redirectURL("pf")}
              disabled={correctorAndProducerStore.loading}
              $slim
            >
              {correctorAndProducerStore.loading
                ? "carregando"
                : "contrate agora"}
            </Button>
          </WrapperLeft>
        </StyledGrid>
        <StyledGrid item sm={12} md={6}>
          <RightImage />
          <WrapperRight>
            <Title>Odonto PME</Title>
            <Subtitle>
              Planos empresariais, de 2 a 29 vidas, com o melhor
              custo-benefício.
            </Subtitle>
            <Button
              type="button"
              variant="contained"
              background="fill"
              bgcolor="#162A6B"
              width="400px"
              onClick={() => redirectURL("pme")}
              $slim
              disabled={correctorAndProducerStore.loading}
            >
              {correctorAndProducerStore.loading
                ? "carregando"
                : "contrate agora"}
            </Button>
          </WrapperRight>
        </StyledGrid>
      </Grid>
      {correctorAndProducerStore.correctorData !== undefined &&
        correctorAndProducerStore.correctorData.corretor_nome !==
          "SULAMERICA" && (
          <CorrectorBox>
            <Grid container>
              <Grid item xs={6} display="flex" justifyContent="end">
                <CorrectorWrapper>
                  <CorrectorTitle>Corretor:</CorrectorTitle>
                  <CorrectorSubtitle>
                    {correctorAndProducerStore.productorData !== undefined
                      ? correctorAndProducerStore.productorData.produtor_nome
                      : correctorAndProducerStore.correctorData.corretor_nome}
                  </CorrectorSubtitle>
                </CorrectorWrapper>
              </Grid>
              <Grid item xs={6}>
                <CorrectorWrapper>
                  <CorrectorTitle>Contato:</CorrectorTitle>
                  <CorrectorSubtitle>
                    {correctorAndProducerStore.productorData !== undefined
                      ? correctorAndProducerStore.productorData
                          .produtor_telefone
                      : correctorAndProducerStore.correctorData
                          .corretor_telefone}
                  </CorrectorSubtitle>
                </CorrectorWrapper>
              </Grid>
            </Grid>
          </CorrectorBox>
        )}
    </Fragment>
  );
});

export default Home;
