import { BaseButton, ButtonProps } from "../styles/ButtonStyles";

export const Button: React.FC<ButtonProps> = (props, { ...rest }) => {
  return (
    <BaseButton
      variant={props.variant}
      type={props.type}
      onClick={props.onClick}
      bgcolor={props.bgcolor}
      fontcolor={props.fontcolor}
      disabled={props.disabled}
      underlined={props.underlined}
      width={props.width}
      startIcon={props.startIcon}
      background={props.background}
      disableRipple={props.disableRipple}
      $slim={props.$slim}
      $squared={props.$squared}
      $shadowbottom={props.$shadowbottom}
      $islink={props.$islink}
      $image={props.$image}
      {...rest}
    >
      {props.children}
    </BaseButton>
  );
};

export default Button;
