import { Container, Typography } from "@mui/material";
import styled from "styled-components";
export const StyledBox = styled.footer`
  && {
    position: relative;

    max-width: 960px;
    margin: 0 auto;
  }
`;

export const ContainerGrid = styled(Container)`
  && {
    padding: 5px 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export const AnsImageLink = styled.a`
  && {
    cursor: pointer;
  }
`;

export const Rights = styled(Typography)`
  && {
    font: Lintel;
    font-size: 13px;
    color: #6d6d6d;
    text-align: center;
  }
`;

export const IconImage = styled.img`
  && {
    padding: 3px;
  }
`;
